<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg viewBox="0 0 58 58"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M43.0134 17.4583C44.1611 18.5219 44.2293 20.3146 43.1656 21.4623L26.4966 39.4489C24.7655 41.3169 21.7915 41.2534 20.1417 39.3132L14.7569 32.9805C13.7433 31.7884 13.8879 30.0003 15.08 28.9866C16.2721 27.973 18.0603 28.1176 19.0739 29.3097L23.425 34.4267L39.0093 17.6105C40.073 16.4628 41.8657 16.3946 43.0134 17.4583Z"
            fill="currentColor"
        />
        <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M29.0007 52.0098C41.7086 52.0098 52.0104 41.7079 52.0104 29C52.0104 16.2921 41.7086 5.99023 29.0007 5.99023C16.2927 5.99023 5.99089 16.2921 5.99089 29C5.99089 41.7079 16.2927 52.0098 29.0007 52.0098ZM29.0007 57.6764C44.8382 57.6764 57.6771 44.8376 57.6771 29C57.6771 13.1624 44.8382 0.323563 29.0007 0.323563C13.1631 0.323563 0.324219 13.1624 0.324219 29C0.324219 44.8376 13.1631 57.6764 29.0007 57.6764Z"
            fill="currentColor"
        />
    </svg>
)
</script>

<style lang="scss" scoped>

</style>
